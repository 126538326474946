<template>
  <div ref="stage5" class="stage5">
    <div ref="fixedEl" class="stage5-inner">
      <div ref="stage5bg" class="stage5-bg"></div>
      <div ref="descContainer" class="desc-container font-light">
        <div ref="desc1" class="desc">we work with global brands,</div>
        <div ref="desc2" class="desc">
          aiming to disrupt the standard of intelligent service
        </div>
        <div ref="desc3" class="desc">
          with strong attention to customer-centric innovation.
        </div>
      </div>
      <div ref="italic" class="italic font-italic">
        Planning an exploration in service of future?
      </div>
      <div ref="contactUs" class="contact-us">
        <div class="font-light">Contact us</div>
        <div class="email font-italic">mrs@mrs.ai</div>
      </div>
    </div>
  </div>
</template>

<script>
import FAnimation, { Animation } from "@/utils/FAnimation";
export default {
  name: "stage5",
  data() {
    return {
      fa: null,
      frameHeight: 0
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.onResize();
    });
    // window.addEventListener("resize", this.onResize, false);
    // this.frameHeight = window.innerHeight / 2;
    // this.dynamicFrameHeight();
    this.frameHeight = this.$root.windowHeight / 2;
    this.fa = new FAnimation(
      this.$refs.stage5,
      this.$refs.fixedEl,
      this.frameHeight
    );
    this.fa.setFrameHeight(this.$root.windowHeight / 2);
    this.fa.createElAnimations(this.$refs.stage5bg, [
      new Animation(FAnimation.animationStore.fadeIn, {}, [-1, 0]),
      new Animation(FAnimation.animationStore.moveRight, { x: 5 }, [-1, 0]),
      new Animation(FAnimation.animationStore.fadeOut, {}, [5, 6]),
      new Animation(FAnimation.animationStore.moveRight, { x: 5, originX: 5 }, [
        5,
        6
      ])
    ]);
    this.fa.createElAnimations(this.$refs.desc1, [
      new Animation(FAnimation.animationStore.fadeIn, {}, [0, 1]),
      new Animation(FAnimation.animationStore.moveRight, { x: 600 / 16 }, [
        0,
        1
      ])
    ]);
    this.fa.createElAnimations(this.$refs.desc2, [
      new Animation(FAnimation.animationStore.fadeIn, {}, [1, 2]),
      new Animation(FAnimation.animationStore.moveRight, { x: 600 / 16 }, [
        1,
        2
      ])
    ]);
    this.fa.createElAnimations(this.$refs.desc3, [
      new Animation(FAnimation.animationStore.fadeIn, {}, [2, 3]),
      new Animation(FAnimation.animationStore.moveRight, { x: 600 / 16 }, [
        2,
        3
      ])
    ]);
    this.fa.createElAnimations(this.$refs.descContainer, [
      new Animation(FAnimation.animationStore.fadeOut, {}, [3, 4]),
      new Animation(FAnimation.animationStore.moveRight, { x: 5 }, [3, 4])
    ]);
    this.fa.createElAnimations(this.$refs.italic, [
      new Animation(FAnimation.animationStore.fadeIn, {}, [4, 5]),
      new Animation(FAnimation.animationStore.moveDown, { y: 2 }, [4, 5]),
      new Animation(FAnimation.animationStore.fadeOut, {}, [5, 6]),
      new Animation(FAnimation.animationStore.moveRight, { x: 5, y: 2 }, [5, 6])
    ]);
    this.fa.createElAnimations(this.$refs.contactUs, [
      new Animation(FAnimation.animationStore.fadeIn, {}, [6, 7]),
      new Animation(FAnimation.animationStore.moveDown, { y: 2 }, [6, 7])
    ]);
    window.addEventListener("scroll", this.execAnimationList, false);
  },
  beforeDestroy() {
    // window.removeEventListener("resize", this.onResize);
    window.removeEventListener("scroll", this.execAnimationList);
    this.fa.destroy();
  },
  methods: {
    onResize() {
      this.$refs.stage5.style.height = this.$root.windowHeight * 5 + "px";
      // this.$refs.stage5.style.height = window.innerHeight * 5 + "px";
    },
    dynamicFrameHeight() {
      window.addEventListener("resize", () => {
        this.frameHeight = window.innerHeight / 2;
      });
    },
    execAnimationList() {
      this.fa.setFixedOpportunity();
      this.fa.execAnimationList();
    }
  }
};
</script>

<style lang="scss" scoped>
.stage5 {
  height: 500vh;
  background-color: #000000;
  color: #fff;
  .stage5-inner {
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    .stage5-bg {
      width: calc(100vw + 10rem);
      height: 100vh;
      position: absolute;
      top: 0;
      left: 0;
      color: red;
      left: -10rem;
      background-image: url("../../../assets/images/bg5.png");
      background-repeat: no-repeat;
      background-size: cover;
      opacity: 0;
    }
    .desc-container {
      position: absolute;
      left: 290px;
      top: 50vh;
      width: 650px;
      font-size: 21px;
      line-height: 32px;
      overflow: hidden;
      .desc {
        position: relative;
        left: -600px;
      }
    }
    .italic {
      position: absolute;
      top: calc(50vh - 2rem);
      left: 290px;
      font-size: 20px;
      line-height: 25px;
      color: rgba(176, 190, 242, 1);
      opacity: 0;
    }
    .contact-us {
      position: absolute;
      display: flex;
      left: 290px;
      top: calc(50vh - 2rem);
      font-size: 20px;
      opacity: 0;
      .email {
        color: rgba(176, 190, 242, 1);
        padding-left: 8px;
      }
    }
  }
}
</style>
