<template>
  <component :is="currentShow"></component>
</template>

<script>
import HomePc from "./Home";
import HomeMobile from "./HomeMobile";
import HomeMiddle from "./HomeMiddle";
const SHOW = {
  PC: "HomePc",
  MIDDLE: "HomeMiddle",
  MOBILE: "HomeMobile"
};
export default {
  data() {
    return {
      SHOW,
      currentShow: SHOW.PC,
      originWidth: 0
    };
  },
  components: {
    HomePc,
    HomeMobile,
    HomeMiddle
  },
  created() {
    this.originWidth = window.innerWidth;
    this.currentShow = this.whichSize();
    this.bindWindowResizeEvent();
  },
  mounted() {
    this.originHeight = window.innerHeight;
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
  methods: {
    bindWindowResizeEvent() {
      window.addEventListener("resize", this.onResize, false);
    },
    whichSize() {
      if (window.innerWidth >= 1100) {
        return this.SHOW.PC;
      } else if (window.innerWidth >= 550) {
        return this.SHOW.MIDDLE;
      } else {
        return this.SHOW.MOBILE;
      }
    },
    onResize() {
      // if (this.originWidth === window.innerWidth) {
      //   return;
      // }

      setTimeout(() => {
        if (this.originWidth === window.innerWidth) {
          return;
        }
        if (this.currentShow === this.whichSize()) {
          this.$forceUpdate();
        } else {
          this.$root.windowHeight = window.innerHeight;
          window.scrollTo(0, 0);
          setTimeout(() => {
            this.originWidth = window.innerWidth;
            this.currentShow = this.whichSize();
          }, 20);
        }
      }, 20);
    }
  }
};
</script>
