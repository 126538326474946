export default class FAnimation {
  container = null;
  fixedEl = null;
  frameHeight = 0;
  fixedOpportunity = {
    top: 0,
    bottom: 0
  };
  waitingAnimationList = [];
  static animationStore = {
    rightEaseOut: {
      before: (el, config) => {
        el.style.transform = `translate3D(0rem, ${config.y ||
          0}rem, ${config.z || 0}rem)`;
      },
      process: (status, el, config) => {
        el.style.transform = `translate3D(${(1 - status) *
          config.x}rem, ${config.y || 0}rem, ${config.z || 0}rem)`;
        el.style.opacity = status;
      },
      end: (el, config) => {
        el.style.transform = `translate3d(${config.x}rem, ${config.y ||
          0}rem, ${config.z || 0}rem)`;
        el.style.opacity = 0;
      },
      startFrame: 0,
      endFrame: 1
    },
    fadeOut: {
      before: el => {
        el.style.opacity = 1;
      },
      process: (status, el) => {
        el.style.opacity = status;
      },
      end: el => {
        el.style.opacity = 0;
      },
      startFrame: 0,
      endFrame: 1
    },
    fadeIn: {
      before: el => {
        el.style.opacity = 0;
      },
      process: (status, el) => {
        el.style.opacity = 1 - status;
      },
      end: el => {
        el.style.opacity = 1;
      },
      startFrame: 0,
      endFrame: 1
    },
    moveRight: {
      before: (el, config) => {
        const originX = config.originX || 0;
        el.style.transform = `translate3d(${originX}rem, ${config.y ||
          0}rem, ${config.z || 0}rem)`;
      },
      process: (status, el, config) => {
        const originX = config.originX || 0;
        el.style.transform = `translate3d(${(1 - status) * config.x +
          originX}rem, ${config.y || 0}rem, ${config.z || 0}rem)`;
      },
      end: (el, config) => {
        const originX = config.originX || 0;
        el.style.transform = `translate3d(${config.x +
          originX}rem, ${config.y || 0}rem, ${config.z || 0}rem)`;
      },
      startFrame: 0,
      endFrame: 1
    },
    moveLeft: {
      before: (el, config) => {
        const originX = -config.originX || 0;
        el.style.transform = `translate3D(${originX}rem, ${config.y ||
          0}rem, ${config.z || 0}rem)`;
      },
      process: (status, el, config) => {
        const originX = -config.originX || 0;
        el.style.transform = `translate3d(${(status - 1) * config.x +
          originX}rem, ${config.y || 0}rem, ${config.z || 0}rem)`;
      },
      end: (el, config) => {
        const originX = -config.originX || 0;
        el.style.transform = `translate3d(${-config.x +
          originX}rem, ${config.y || 0}rem, ${config.z || 0}rem)`;
      },
      startFrame: 0,
      endFrame: 1
    },
    moveUp: {
      before: (el, config) => {
        const originY = -config.originY || 0;
        el.style.transform = `translate3D(${config.x ||
          0}rem, ${originY}rem, ${config.z || 0}rem)`;
      },
      process: (status, el, config) => {
        const originY = -config.originY || 0;
        el.style["transform"] = `translate3d(${config.x || 0}rem,
          ${(status - 1) * config.y + originY}rem, ${config.z || 0}rem)`;
      },
      end: (el, config) => {
        const originY = -config.originY || 0;
        el.style["transform"] = `translate3d(${config.x || 0}rem,
          ${-config.y + originY}rem, ${config.z || 0}rem)`;
      },
      startFrame: 0,
      endFrame: 1
    },
    moveDown: {
      before: (el, config) => {
        const originY = config.originY || 0;
        el.style.transform = `translate3D(${config.x ||
          0}rem, ${originY}rem, ${config.z || 0}rem)`;
      },
      process: (status, el, config) => {
        const originY = config.originY || 0;
        el.style["transform"] = `translate3d(${config.x || 0}rem,
          ${(1 - status) * config.y + originY}rem, ${config.z || 0}rem)`;
      },
      end: (el, config) => {
        const originY = config.originY || 0;
        el.style["transform"] = `translate3d(${config.x || 0}rem,
          ${config.y + originY}rem, ${config.z || 0}rem)`;
      },
      startFrame: 0,
      endFrame: 1
    }
  };
  constructor(container, fixedEl, top = 0, bottom = 0) {
    this.container = container;
    this.fixedEl = fixedEl || null;
    this.fixedOpportunity.top = top;
    this.fixedOpportunity.bottom = bottom;
    this.frameHeight = window.innerHeight / 2;
    // this.dynamicFrame();
  }
  // dynamicFrame() {
  //   window.addEventListener("resize", this.onResize);
  // }
  destroy() {
    // window.removeEventListener("resize", this.onResize);
  }
  // onResize = () => {
  //   this.frameHeight = window.innerHeight / 2;
  // };
  setFrameHeight(height) {
    this.frameHeight = height;
  }
  setFixedOpportunity() {
    const domRect = this.container.getBoundingClientRect();
    if (
      domRect.top <= this.fixedOpportunity.top &&
      domRect.bottom >= this.fixedOpportunity.bottom
    ) {
      this.fixedEl.style.position = "fixed";
    } else {
      this.fixedEl.style.position = "relative";
    }
  }
  execAnimation(
    el,
    startFrame,
    endFrame,
    beforeCallback,
    animationCallback,
    endStyleCallback,
    config
  ) {
    if (!this.container) {
      return;
    }
    const domRect = this.container.getBoundingClientRect();
    if (
      -domRect.top >= this.frameHeight * startFrame &&
      -domRect.top <= this.frameHeight * endFrame
    ) {
      let status = computeIncrement(
        startFrame * this.frameHeight,
        endFrame * this.frameHeight,
        -domRect.top
      );
      config.backClear = true;
      animationCallback(status, el, config);
    } else if (-domRect.top > this.frameHeight * endFrame) {
      endStyleCallback(el, config);
    } else {
      if (config.backClear) {
        beforeCallback(el, config);
        config.backClear = false;
      }
    }
  }
  createElAnimations(el, animations = []) {
    animations.forEach(animation => animation.setEl(el));
    this.waitingAnimationList = this.waitingAnimationList.concat(animations);
  }
  execAnimationList() {
    this.waitingAnimationList.forEach(animation => {
      this.execAnimation(
        animation.el,
        animation.startFrame,
        animation.endFrame,
        animation.before,
        animation.process,
        animation.end,
        animation.config
      );
    });
  }
  clearAnimation() {
    this.waitingAnimationList.forEach(animation => {
      if (animation.el) {
        animation.el.style = "";
      }
    });
  }
}

export class Animation {
  el = null;
  startFrame;
  endFrame;
  before;
  process;
  end;
  config = {};
  constructor(animationDesc, config = {}, frames = []) {
    this.process = animationDesc.process;
    this.end = animationDesc.end;
    this.before = animationDesc.before;
    this.startFrame =
      frames[0] !== undefined ? frames[0] : animationDesc.startFrame;
    this.endFrame =
      frames[1] !== undefined ? frames[1] : animationDesc.endFrame;
    this.config = config;
  }
  setEl(el) {
    this.el = el;
  }
  setConfig(config) {
    this.config = config;
  }
  getConfig() {
    return this.config;
  }
}

export function computeIncrement(top1, top2, top) {
  const k = 1 / (top1 - top2);
  const x = top;
  const delta = top2 / (top2 - top1);
  const value = k * x + delta;
  return value;
}
