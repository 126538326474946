<template>
  <div ref="stage1" class="stage1">
    <div ref="fixedEl" class="stage1-inner">
      <div ref="definition" class="service-definition">
        <div class="mass-noun font-italic">
          <span class="mass-noun-inner">[mass noun]</span>
        </div>
        <div class="service-meaning font-light">
          <span class="service-meaning-inner">
            the action of helping or doing work for someone
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FAnimation, { Animation } from "@/utils/FAnimation";
export default {
  name: "stage1",
  data() {
    return {
      fa: null
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.onResize();
    });
    // window.addEventListener("resize", this.onResize, false);
    this.fa = new FAnimation(this.$refs.stage1, this.$refs.fixedEl);
    this.fa.setFrameHeight(this.$root.windowHeight / 2);
    this.fa.createElAnimations(this.$refs.definition, [
      new Animation(FAnimation.animationStore.rightEaseOut, { x: 5 })
    ]);
    window.addEventListener("scroll", this.execAnimationList, false);
  },
  beforeDestroy() {
    // window.removeEventListener("resize", this.onResize);
    window.removeEventListener("scroll", this.execAnimationList);
    this.fa.destroy();
  },
  methods: {
    onResize() {
      this.$refs.stage1.style.height = this.$root.windowHeight + "px";
      // this.$refs.stage1.style.height = window.innerHeight + "px";
    },
    execAnimationList() {
      this.fa.setFixedOpportunity();
      this.fa.execAnimationList();
    }
  }
};
</script>

<style lang="scss" scoped>
.stage1 {
  height: 100vh;
  background-color: #000000;
  color: #fff;
  .stage1-inner {
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
  }
  .service-definition {
    position: relative;
    opacity: 0.971631;
    overflow: hidden;
    position: relative;
    height: 2.5rem;
    line-height: 2.5rem;
    top: calc(50vh + 5px);
  }
  .mass-noun {
    line-height: 25px;
    position: absolute;
    color: rgba(176, 190, 242, 1);
    font-size: 21px;
    line-height: 2.5rem;
    font-weight: 100;
    left: 290px;
    .mass-noun-inner {
      position: relative;
      top: -3rem;
      opacity: 0;
      animation: down 0.5s linear 0.8s 1 forwards;
    }
    @keyframes down {
      100% {
        top: 0;
        opacity: 1;
      }
    }
  }
  .service-meaning {
    position: absolute;
    line-height: 25px;
    font-weight: 200;
    font-size: 21px;
    line-height: 2.5rem;
    left: 27rem;
    overflow: hidden;
    .service-meaning-inner {
      position: relative;
      left: -35rem;
      opacity: 0;
      animation: right 0.5s linear 1.3s 1 forwards;
    }
    @keyframes right {
      100% {
        left: 0;
        opacity: 1;
      }
    }
  }
}
</style>
