<template>
  <div ref="stage3" class="stage3">
    <div ref="fixedEl" class="stage3-inner">
      <div ref="stage3bg" class="stage3-bg"></div>
      <div ref="intelligent" class="intelligent font-light">Intelligent</div>
      <div ref="desc1" class="short-desc short-desc1">
        <div class="title font-italic">INTUITIVE</div>
        <div ref="content1" class="font-light content content1">
          through interactions of business operation and service delivery
        </div>
      </div>
      <div ref="desc2" class="short-desc short-desc2">
        <div class="title font-italic">PROFESSIONAL</div>
        <div ref="content2" class="font-light content content2">
          in decision-making for customers in vertical domains
        </div>
      </div>
      <div ref="desc3" class="short-desc short-desc3">
        <div class="title font-italic">PERSONAL</div>
        <div ref="content3" class="font-light content content3">
          with full consideration of individual customer life journey
        </div>
      </div>
      <div ref="desc4" class="short-desc short-desc4">
        <div class="title font-italic">AUTOMATED</div>
        <div ref="content4" class="font-light content content4">
          by machine intelligence with human touch
        </div>
      </div>
      <div ref="text" class="text font-light">
        This level of service is delivered with...
      </div>
    </div>
  </div>
</template>

<script>
import FAnimation, { Animation } from "@/utils/FAnimation";
export default {
  name: "stage3",
  data() {
    return {
      fa: null,
      frameHeight: 0
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.onResize();
    });
    // window.addEventListener("resize", this.onResize, false);
    // this.frameHeight = window.innerHeight / 2;
    // this.dynamicFrameHeight();
    this.frameHeight = this.$root.windowHeight / 2;
    this.fa = new FAnimation(
      this.$refs.stage3,
      this.$refs.fixedEl,
      this.frameHeight
    );
    this.fa.setFrameHeight(this.$root.windowHeight / 2);
    this.fa.clearAnimation();
    this.fa.createElAnimations(this.$refs.intelligent, [
      new Animation(FAnimation.animationStore.fadeIn, {}, [-1, 0]),
      new Animation(FAnimation.animationStore.moveRight, { x: 15 }, [-1, 0]),
      new Animation(FAnimation.animationStore.moveUp, { x: 15, y: 35 / 16 }, [
        2,
        3
      ]),
      new Animation(
        FAnimation.animationStore.moveUp,
        { x: 15, y: 35 / 16, originY: 35 / 16 },
        [4, 5]
      ),
      new Animation(
        FAnimation.animationStore.moveUp,
        { x: 15, y: 35 / 16, originY: 70 / 16 },
        [6, 7]
      ),
      new Animation(
        FAnimation.animationStore.moveDown,
        { x: 15, y: 105 / 16, originY: -105 / 16 },
        [8, 9]
      ),
      new Animation(FAnimation.animationStore.fadeOut, {}, [10, 11]),
      new Animation(
        FAnimation.animationStore.moveRight,
        { x: 4, originX: 15 },
        [10, 11]
      )
    ]);
    this.fa.createElAnimations(this.$refs.stage3bg, [
      new Animation(FAnimation.animationStore.fadeIn, {}, [-1, 0]),
      new Animation(FAnimation.animationStore.moveRight, { x: 5 }, [-1, 0]),
      new Animation(FAnimation.animationStore.fadeOut, {}, [12, 13])
    ]);
    this.fa.createElAnimations(this.$refs.desc1, [
      new Animation(FAnimation.animationStore.fadeIn, {}, [0, 1]),
      new Animation(FAnimation.animationStore.moveDown, { y: 1.5 }, [0, 1]),
      new Animation(
        FAnimation.animationStore.moveDown,
        { originY: 1.5, y: 35 / 16 },
        [2, 3]
      ),
      new Animation(
        FAnimation.animationStore.moveDown,
        { originY: 35 / 16 + 1.5, y: 35 / 16 },
        [4, 5]
      ),
      new Animation(
        FAnimation.animationStore.moveDown,
        { originY: 70 / 16 + 1.5, y: 35 / 16 },
        [6, 7]
      ),
      new Animation(
        FAnimation.animationStore.moveRight,
        { x: 5, y: 105 / 16 + 1.5 },
        [8, 9]
      ),
      new Animation(FAnimation.animationStore.fadeOut, {}, [8, 9])
    ]);
    this.fa.createElAnimations(this.$refs.content1, [
      new Animation(FAnimation.animationStore.fadeIn, {}, [1, 2]),
      new Animation(FAnimation.animationStore.moveDown, { y: 1 }, [1, 2])
    ]);
    this.fa.createElAnimations(this.$refs.desc2, [
      new Animation(FAnimation.animationStore.fadeIn, {}, [2, 3]),
      new Animation(FAnimation.animationStore.moveDown, { y: 1.5 }, [2, 3]),
      new Animation(
        FAnimation.animationStore.moveDown,
        { originY: 1.5, y: 35 / 16 },
        [4, 5]
      ),
      new Animation(
        FAnimation.animationStore.moveDown,
        { originY: 1.5 + 35 / 16, y: 35 / 16 },
        [6, 7]
      ),
      new Animation(
        FAnimation.animationStore.moveRight,
        { x: 5, y: 70 / 16 + 1.5 },
        [8, 9]
      ),
      new Animation(FAnimation.animationStore.fadeOut, {}, [8, 9])
    ]);
    this.fa.createElAnimations(this.$refs.content2, [
      new Animation(FAnimation.animationStore.fadeIn, {}, [3, 4]),
      new Animation(FAnimation.animationStore.moveDown, { y: 1 }, [3, 4])
    ]);
    this.fa.createElAnimations(this.$refs.desc3, [
      new Animation(FAnimation.animationStore.fadeIn, {}, [4, 5]),
      new Animation(FAnimation.animationStore.moveDown, { y: 1.5 }, [4, 5]),
      new Animation(
        FAnimation.animationStore.moveDown,
        { originY: 1.5, y: 35 / 16 },
        [6, 7]
      ),
      new Animation(
        FAnimation.animationStore.moveRight,
        { x: 5, y: 35 / 16 + 1.5 },
        [8, 9]
      ),
      new Animation(FAnimation.animationStore.fadeOut, {}, [8, 9])
    ]);
    this.fa.createElAnimations(this.$refs.content3, [
      new Animation(FAnimation.animationStore.fadeIn, {}, [5, 6]),
      new Animation(FAnimation.animationStore.moveDown, { y: 1 }, [5, 6])
    ]);
    this.fa.createElAnimations(this.$refs.desc4, [
      new Animation(FAnimation.animationStore.fadeIn, {}, [6, 7]),
      new Animation(FAnimation.animationStore.moveDown, { y: 1.5 }, [6, 7]),
      new Animation(FAnimation.animationStore.moveRight, { x: 5, y: 1.5 }, [
        8,
        9
      ]),
      new Animation(FAnimation.animationStore.fadeOut, {}, [8, 9])
    ]);
    this.fa.createElAnimations(this.$refs.content4, [
      new Animation(FAnimation.animationStore.fadeIn, {}, [7, 8]),
      new Animation(FAnimation.animationStore.moveDown, { y: 1 }, [7, 8])
    ]);
    this.fa.createElAnimations(this.$refs.text, [
      new Animation(FAnimation.animationStore.fadeIn, {}, [9, 10]),
      new Animation(FAnimation.animationStore.moveDown, { y: 1.5 }, [9, 10]),
      new Animation(FAnimation.animationStore.fadeOut, {}, [10, 11]),
      new Animation(FAnimation.animationStore.moveRight, { x: 5, y: 1.5 }, [
        10,
        11
      ])
    ]);
    window.addEventListener("scroll", this.execAnimationList, false);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
    window.removeEventListener("scroll", this.execAnimationList);
    this.fa.destroy();
  },
  methods: {
    dynamicFrameHeight() {
      window.addEventListener("resize", () => {
        this.frameHeight = window.innerHeight / 2;
      });
    },
    onResize() {
      this.$refs.stage3.style.height = this.$root.windowHeight * 6.5 + "px";
      // this.$refs.stage3.style.height = window.innerHeight * 6.5 + "px";
    },
    execAnimationList() {
      this.fa.setFixedOpportunity();
      this.fa.execAnimationList();
    }
  }
};
</script>

<style lang="scss" scoped>
.stage3 {
  height: 650vh;
  background-color: #000000;
  color: #fff;
  .stage3-inner {
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    .stage3-bg {
      width: calc(100vw + 5rem);
      height: 100vh;
      position: absolute;
      top: 0;
      left: 0;
      color: red;
      left: -5rem;
      background-image: url("../../../assets/images/bg2.png");
      background-repeat: no-repeat;
      background-size: cover;
      opacity: 0;
    }
    .intelligent {
      position: absolute;
      top: calc(50vh - 65px);
      font-size: 40px;
      left: calc(40px - 15rem);
      opacity: 0;
    }
    .short-desc {
      position: absolute;
      top: calc(50vh - 1.5rem + 57px);
      left: 40px;
      display: flex;
      width: 800px;
      opacity: 0;
      line-height: 20px;
      font-size: 14px;
      opacity: 0;
      &.short-desc2 {
        top: calc(50vh - 1.5rem + 22px);
      }
      &.short-desc3 {
        top: calc(50vh - 1.5rem - 13px);
      }
      &.short-desc4 {
        top: calc(50vh - 1.5rem - 48px);
      }
      .title {
        flex-shrink: 0;
        color: rgba(176, 190, 242, 1);
      }
      .content {
        position: absolute;
        position: absolute;
        top: 8px;
        width: calc(100vw - 80px);
        opacity: 0;
      }
    }
    .text {
      position: absolute;
      line-height: 20px;
      top: calc(50vh - 1.5rem + 57px);
      left: 40px;
      font-size: 14px;
      font-weight: 200;
      opacity: 0;
    }
  }
}
</style>
