<template>
  <div
    ref="app"
    id="app"
    @touchstart="handleTouchStart"
    @touchmove="handleTouchMove"
  >
    <!-- <div
      v-click-outside="() => (menuHover = false)"
      ref="menuRef"
      class="menu-icon"
    >
      <img
        @click="toggleMenu"
        :class="{ 'rotate-45': menuHover }"
        class="menu-icon-img"
        src="./assets/icons/menu-icon.png"
      />
      <menus
        :class="{ 'menu-active': menuHover }"
        class="menus-main transition-all duration-200 transform"
        :links="links"
      />
    </div> -->
    <router-view />
  </div>
</template>

<script>
// import Menus from "./components/menus";
import ClickOutside from "vue-click-outside";

export default {
  name: "app",
  directives: {
    ClickOutside
  },
  //   components: {
  //     Menus
  //   },
  created() {
    // this.originWidth = window.innerWidth;
    // this.onResize();
    // window.addEventListener("resize", this.onResize, false);
    this.$root.windowHeight = window.innerHeight;
    function stopScrolling(touchEvent) {
      touchEvent.preventDefault();
    }
    document.addEventListener("touchstart", stopScrolling, false);
    document.addEventListener("touchmove", stopScrolling, false);
  },
  data() {
    return {
      originWidth: 0,
      startX: 0,
      startY: 0,
      menuHover: false,
      links: [
        {
          link: "https://mrs.ai/kudos/",
          title: "Kudos to Interns"
        }
      ]
    };
  },
  computed: {
    isWx() {
      let ua = navigator.userAgent.toLowerCase();
      return ua.indexOf("micromessenger") !== -1;
    }
  },
  mounted() {
    if (this.isWx) {
      // document.addEventListener("touchstart", this.onTouchStart, false);
      // document.body.addEventListener("touchmove", this.onTouchMove, false);
    }
  },
  methods: {
    toggleMenu() {
      this.menuHover = !this.menuHover;
    },
    handleTouchStart(event) {
      if (!this.isWx) {
        return;
      }
      const touch = event.touches[0];
      this.startY = Number(touch.pageY);
    },
    handleTouchMove(event) {
      if (!this.isWx) {
        return;
      }
      const touch = event.touches[0];
      const moveLength = Number(touch.pageY) - this.startY;
      if (moveLength > 0 && window.scrollY <= 0) {
        event.preventDefault();
        return false;
      }
    },
    onResize() {
      if (window.innerWidth >= 1100 && this.$route.name !== "Home") {
        this.$router.push({
          name: "Home"
        });
      }
      if (
        window.innerWidth >= 550 &&
        window.innerWidth < 1100 &&
        this.$route.name !== "HomeMiddle"
      ) {
        this.$router.push({
          name: "HomeMiddle"
        });
      }
      if (window.innerWidth < 550 && this.$route.name !== "HomeMobile") {
        this.$router.push({
          name: "HomeMobile"
        });
      }
    }
  }
};
</script>

<style lang="scss">
#app {
  position: relative;
}
.menu-icon {
  position: fixed;
  right: 20px;
  top: 20px;
  width: 2rem;
  height: 2rem;
  z-index: 99999;
  .menus-main {
    opacity: 0;
    visibility: hidden;
  }
  .menu-icon-img {
    // menu-icon-img w-full h-full cursor-pointer transition-transform duration-200 transform
    width: 100%;
    height: 100%;
    cursor: pointer;
    transition-property: transform;
    transition-duration: 0.3s;
  }
  .menu-active {
    opacity: 1;
    visibility: visible;
  }
  .rotate-45 {
    transform: rotate(45deg);
  }
}
</style>
