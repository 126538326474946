<template>
  <div ref="stage2" class="stage2">
    <div ref="fixedEl" class="stage2-inner">
      <div ref="stage2bg" class="stage2-bg"></div>
      <div ref="future" class="future font-light">of Future</div>
      <div ref="text1" class="stage2-common-text font-light">
        Customers are expecting seamless and intuitive professional service.
      </div>
      <div ref="text2" class="stage2-common-text font-light">
        We help the global industrial leaders to design and co-build the
        future-facing solution
      </div>
      <div ref="text3" class="stage2-common-text font-light via">via...</div>
    </div>
  </div>
</template>

<script>
import FAnimation, { Animation } from "@/utils/FAnimation";
export default {
  name: "stage2",
  data() {
    return {
      fa: null,
      frameHeight: 0
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.onResize();
    });
    // window.addEventListener("resize", this.onResize, false);
    // this.frameHeight = window.innerHeight / 2;
    // this.dynamicFrameHeight();
    this.frameHeight = this.$root.windowHeight / 2;
    this.fa = new FAnimation(
      this.$refs.stage2,
      this.$refs.fixedEl,
      this.frameHeight
    );
    this.fa.setFrameHeight(this.$root.windowHeight / 2);
    this.fa.createElAnimations(this.$refs.future, [
      new Animation(FAnimation.animationStore.fadeIn, {}, [-1, 0]),
      new Animation(FAnimation.animationStore.fadeOut, {}, [5, 6]),
      new Animation(FAnimation.animationStore.rightEaseOut, { x: 5 }, [5, 6])
    ]);
    this.fa.createElAnimations(this.$refs.stage2bg, [
      new Animation(FAnimation.animationStore.fadeIn, {}, [-1, 0]),
      new Animation(FAnimation.animationStore.moveRight, { x: 5 }, [-1, 0]),
      new Animation(FAnimation.animationStore.fadeOut, {}, [6, 7])
    ]);
    this.fa.createElAnimations(this.$refs.text1, [
      new Animation(FAnimation.animationStore.fadeIn, {}, [0, 1]),
      new Animation(FAnimation.animationStore.moveDown, { y: 2 }, [0, 1]),
      new Animation(FAnimation.animationStore.fadeOut, {}, [1, 2]),
      new Animation(FAnimation.animationStore.rightEaseOut, { x: 5, y: 2 }, [
        1,
        2
      ])
    ]);
    this.fa.createElAnimations(this.$refs.text2, [
      new Animation(FAnimation.animationStore.fadeIn, {}, [2, 3]),
      new Animation(FAnimation.animationStore.moveDown, { y: 2 }, [2, 3]),
      new Animation(FAnimation.animationStore.fadeOut, {}, [3, 4]),
      new Animation(FAnimation.animationStore.rightEaseOut, { x: 5, y: 2 }, [
        3,
        4
      ])
    ]);
    this.fa.createElAnimations(this.$refs.text3, [
      new Animation(FAnimation.animationStore.fadeIn, {}, [4, 5]),
      new Animation(FAnimation.animationStore.moveDown, { y: 2 }, [4, 5]),
      new Animation(FAnimation.animationStore.fadeOut, {}, [5, 6]),
      new Animation(FAnimation.animationStore.rightEaseOut, { x: 5, y: 2 }, [
        5,
        6
      ])
    ]);
    window.addEventListener("scroll", this.execAnimationList, false);
  },
  beforeDestroy() {
    // window.removeEventListener("resize", this.onResize);
    window.removeEventListener("scroll", this.execAnimationList);
    this.fa.destroy();
  },
  methods: {
    onResize() {
      this.$refs.stage2.style.height = this.$root.windowHeight * 3.5 + "px";
      // this.$refs.stage2.style.height = window.innerHeight * 3.5 + "px";
    },
    dynamicFrameHeight() {
      window.addEventListener("resize", () => {
        this.frameHeight = window.innerHeight / 2;
      });
    },
    execAnimationList() {
      this.fa.setFixedOpportunity();
      this.fa.execAnimationList();
    }
  }
};
</script>

<style lang="scss" scoped>
.stage2 {
  height: 350vh;
  background-color: #000000;
  color: #fff;
  .stage2-inner {
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    .stage2-bg {
      width: calc(100vw + 5rem);
      height: 100vh;
      position: absolute;
      top: 0;
      left: -5rem;
      background-image: url("../../../assets/images/bg1.png");
      background-repeat: no-repeat;
      background-size: cover;
      opacity: 0;
    }
  }
  .future {
    position: absolute;
    top: calc(50vh - 16px - 65px);
    font-size: 65px;
    left: 549px;
    opacity: 0;
    font-weight: 200;
  }
  .stage2-common-text {
    width: 650px;
    line-height: 32px;
    position: absolute;
    top: calc(50vh - 2rem);
    left: 290px;
    font-size: 20px;
    font-weight: 200;
    opacity: 0;
  }
  .via {
    color: rgba(176, 190, 242, 1);
  }
  .default {
    opacity: 0;
  }
}
</style>
