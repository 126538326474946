import Vue from "vue";
import VueRouter from "vue-router";
import HomeSwitch from "../views/HomeSwitch.vue";
// import Home from "../views/Home.vue";
// import HomeMiddle from "../views/HomeMiddle.vue";
// import HomeMobile from "../views/HomeMobile.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: HomeSwitch
  }
  // {
  //   path: "/",
  //   name: "Home",
  //   component: Home
  // },
  // {
  //   path: "/middle",
  //   name: "HomeMiddle",
  //   component: HomeMiddle
  // },
  // {
  //   path: "/mobile",
  //   name: "HomeMobile",
  //   component: HomeMobile
  // }
  // {
  //   path: "/about",
  //   name: "about",
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "../views/About.vue")
  // }
];

const router = new VueRouter({
  routes,
  mode: "history"
});

export default router;
