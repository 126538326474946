<template>
  <div ref="stage4" class="stage4">
    <div ref="fixedEl" class="stage4-inner">
      <div ref="stage4bg1" class="stage4-bg bg1"></div>
      <div ref="stage4bg2" class="stage4-bg bg2"></div>
      <div ref="title" class="title">
        <div ref="aiBox" class="ai font-light">
          <div ref="aiInner" class="ai-inner">AI</div>
        </div>
        <div ref="agentBox" class="agent font-light">
          <div ref="agentInner" class="agent-inner">Agent</div>
        </div>
      </div>
      <div ref="italic1" class="stage4-common-text italic italic1 font-italic">
        Built with…
      </div>
      <div ref="desc1" class="stage4-common-text desc font-light">
        Conversational Artificial Intelligence.
      </div>
      <div ref="detail1" class="stage4-common-text detail detail1 font-light">
        It is designed to realize enterprise level,
      </div>
      <div ref="detail2" class="stage4-common-text detail detail2 font-light">
        Business Service Process Automation
      </div>
      <div ref="italic2" class="stage4-common-text italic italic2 font-italic">
        Multimodality & Cross Device
      </div>
      <div ref="detail3" class="stage4-common-text detail detail3 font-light">
        enable customers to get service in text, voice and touch on any
        connected devices.
      </div>
    </div>
  </div>
</template>

<script>
import FAnimation, { Animation } from "@/utils/FAnimation";
export default {
  name: "stage4",
  data() {
    return {
      fa: null,
      frameHeight: 0
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.onResize();
    });
    // window.addEventListener("resize", this.onResize, false);
    // this.frameHeight = window.innerHeight / 2;
    // this.dynamicFrameHeight();
    this.frameHeight = this.$root.windowHeight / 2;
    this.fa = new FAnimation(
      this.$refs.stage4,
      this.$refs.fixedEl,
      this.frameHeight
    );
    this.fa.setFrameHeight(this.$root.windowHeight / 2);
    this.fa.clearAnimation();
    this.fa.createElAnimations(this.$refs.stage4bg1, [
      new Animation(FAnimation.animationStore.fadeIn, {}, [-1, 0]),
      new Animation(FAnimation.animationStore.moveRight, { x: 5 }, [-1, 0]),
      new Animation(FAnimation.animationStore.fadeOut, {}, [6, 7])
    ]);
    this.fa.createElAnimations(this.$refs.stage4bg2, [
      new Animation(FAnimation.animationStore.fadeIn, {}, [6, 7]),
      new Animation(FAnimation.animationStore.moveRight, { x: 5 }, [6, 7]),
      new Animation(FAnimation.animationStore.fadeOut, {}, [10, 11])
    ]);
    this.fa.createElAnimations(this.$refs.title, [
      new Animation(FAnimation.animationStore.fadeIn, {}, [-1, 0]),
      new Animation(FAnimation.animationStore.moveRight, { x: 12 }, [-1, 0])
    ]);
    this.fa.createElAnimations(this.$refs.italic1, [
      new Animation(FAnimation.animationStore.fadeIn, {}, [0, 1]),
      new Animation(FAnimation.animationStore.moveDown, { y: 1.5 }, [0, 1]),
      new Animation(FAnimation.animationStore.fadeOut, {}, [1, 2]),
      new Animation(FAnimation.animationStore.moveRight, { x: 5, y: 1.5 }, [
        1,
        2
      ])
    ]);
    this.fa.createElAnimations(this.$refs.desc1, [
      new Animation(FAnimation.animationStore.fadeIn, {}, [2, 3]),
      new Animation(FAnimation.animationStore.moveDown, { y: 1.5 }, [2, 3]),
      new Animation(FAnimation.animationStore.fadeOut, {}, [9, 10]),
      new Animation(FAnimation.animationStore.moveRight, { x: 5, y: 1.5 }, [
        9,
        10
      ])
    ]);
    this.fa.createElAnimations(this.$refs.detail1, [
      new Animation(FAnimation.animationStore.fadeIn, {}, [3, 4]),
      new Animation(FAnimation.animationStore.moveDown, { y: 1 }, [3, 4]),
      new Animation(FAnimation.animationStore.fadeOut, {}, [5, 6]),
      new Animation(FAnimation.animationStore.moveRight, { x: 5, y: 1 }, [5, 6])
    ]);
    this.fa.createElAnimations(this.$refs.detail2, [
      new Animation(FAnimation.animationStore.fadeIn, {}, [4, 5]),
      new Animation(FAnimation.animationStore.moveDown, { y: 1 }, [4, 5]),
      new Animation(FAnimation.animationStore.fadeOut, {}, [5, 6]),
      new Animation(FAnimation.animationStore.moveRight, { x: 5, y: 1 }, [5, 6])
    ]);
    this.fa.createElAnimations(this.$refs.italic2, [
      new Animation(FAnimation.animationStore.fadeIn, {}, [6, 7]),
      new Animation(FAnimation.animationStore.moveDown, { y: 1 }, [6, 7]),
      new Animation(FAnimation.animationStore.fadeOut, {}, [7, 8]),
      new Animation(FAnimation.animationStore.moveRight, { x: 5, y: 1 }, [7, 8])
    ]);
    this.fa.createElAnimations(this.$refs.detail3, [
      new Animation(FAnimation.animationStore.fadeIn, {}, [8, 9]),
      new Animation(FAnimation.animationStore.moveDown, { y: 1 }, [8, 9]),
      new Animation(FAnimation.animationStore.fadeOut, {}, [9, 10]),
      new Animation(FAnimation.animationStore.moveRight, { x: 5, y: 1 }, [
        9,
        10
      ])
    ]);
    this.fa.createElAnimations(this.$refs.agentInner, [
      new Animation(FAnimation.animationStore.fadeOut, {}, [9, 10]),
      // new Animation(FAnimation.animationStore.moveLeft, { x: 26 }, [13, 14])
      new Animation(FAnimation.animationStore.moveUp, { y: 4 }, [9, 10])
    ]);
    this.fa.createElAnimations(this.$refs.aiInner, [
      new Animation(FAnimation.animationStore.fadeOut, {}, [9, 10]),
      new Animation(FAnimation.animationStore.moveRight, { x: 5 }, [9, 10])
    ]);
    window.addEventListener("scroll", this.execAnimationList, false);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
    window.removeEventListener("scroll", this.execAnimationList);
    this.fa.destroy();
  },
  methods: {
    dynamicFrameHeight() {
      window.addEventListener("resize", () => {
        this.frameHeight = window.innerHeight / 2;
      });
    },
    onResize() {
      this.$refs.stage4.style.height = this.$root.windowHeight * 5.5 + "px";
      // this.$refs.stage4.style.height = window.innerHeight * 5.5 + "px";
    },
    execAnimationList() {
      this.fa.setFixedOpportunity();
      this.fa.execAnimationList();
    }
  }
};
</script>

<style lang="scss" scoped>
.stage4 {
  height: 550vh;
  background-color: #000000;
  color: #fff;
  .stage4-inner {
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    .stage4-bg {
      width: calc(100vw + 5rem);
      height: 100vh;
      position: absolute;
      top: 0;
      left: 0;
      color: red;
      left: -5rem;
      background-repeat: no-repeat;
      background-size: cover;
      opacity: 0;
      &.bg1 {
        background-image: url("../../../assets/images/bg3.png");
      }
      &.bg2 {
        background-image: url("../../../assets/images/bg4-1.png");
      }
    }
    .title {
      position: relative;
      font-size: 45px;
      top: calc(50vh - 65px);
      left: calc(40px - 12rem);
      opacity: 0;
      .ai {
        width: 60px;
        position: absolute;
        overflow: hidden;
      }
      .agent {
        position: absolute;
        top: 46px;
        height: 61px;
        box-sizing: border-box;
        padding-top: 11px;
        overflow: hidden;
      }
    }
    .stage4-common-text {
      line-height: 20px;
      position: relative;
      // top: calc(50vh - 1.5rem + 57px);
      top: calc(50vh - 2.5rem + 57px);
      left: 40px;
      padding-right: 40px;
      font-size: 14px;
      font-weight: 200;
      opacity: 0;
      &.detail1,
      &.detail3 {
        // top: calc(50vh + 1rem + 57px);
        top: calc(50vh - 1rem + 57px);
      }
      &.detail3 {
        top: calc(50vh - 5rem + 57px);
      }
      &.detail2 {
        // top: calc(50vh + 2.5rem + 57px);
        top: calc(50vh - 0.5rem + 57px);
      }
      &.italic {
        color: rgba(176, 190, 242, 1);
      }
      &.italic1 {
        color: rgba(176, 190, 242, 1);
        top: calc(50vh - 1.5rem + 57px);
      }
      &.italic2 {
        // top: calc(50vh + 1rem + 57px);
        top: calc(50vh - 4rem + 57px);
      }
      &.desc {
        line-height: 25px;
        font-size: 16px;
      }
    }
  }
}
</style>
