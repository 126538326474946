<template>
  <div ref="stage3" class="stage3">
    <div ref="fixedEl" class="stage3-inner">
      <div ref="stage3bg" class="stage3-bg"></div>
      <div ref="intelligent" class="intelligent font-light">Intelligent</div>
      <div ref="desc1" class="short-desc">
        <div class="title font-italic">INTUITIVE</div>
        <div class="content font-light">
          <div ref="content1" class="content-inner">
            through interactions of business operation and service delivery
          </div>
        </div>
      </div>
      <div ref="desc2" class="short-desc">
        <div class="title font-italic">PROFESSIONAL</div>
        <div class="content font-light">
          <div ref="content2" class="content-inner">
            in decision-making for customers in vertical domains
          </div>
        </div>
      </div>
      <div ref="desc3" class="short-desc">
        <div class="title font-italic">PERSONAL</div>
        <div class="content font-light">
          <div ref="content3" class="content-inner">
            with full consideration of individual customer life journey
          </div>
        </div>
      </div>
      <div ref="desc4" class="short-desc">
        <div class="title font-italic">AUTOMATED</div>
        <div class="content font-light">
          <div ref="content4" class="content-inner">
            by machine intelligence with human touch
          </div>
        </div>
      </div>
      <div ref="text" class="text font-light">
        This level of service is delivered with...
      </div>
    </div>
  </div>
</template>

<script>
import FAnimation, { Animation } from "@/utils/FAnimation";
export default {
  name: "stage3",
  data() {
    return {
      fa: null,
      frameHeight: 0
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.onResize();
    });
    // window.addEventListener("resize", this.onResize, false);
    // this.frameHeight = window.innerHeight / 2;
    // this.dynamicFrameHeight();
    this.frameHeight = this.$root.windowHeight / 2;
    this.fa = new FAnimation(
      this.$refs.stage3,
      this.$refs.fixedEl,
      this.frameHeight
    );
    this.fa.setFrameHeight(this.$root.windowHeight / 2);
    this.fa.createElAnimations(this.$refs.intelligent, [
      new Animation(FAnimation.animationStore.fadeIn, {}, [-1, 0]),
      new Animation(FAnimation.animationStore.moveRight, { x: 3.25 }, [-1, 0]),
      new Animation(FAnimation.animationStore.fadeOut, {}, [11, 12]),
      new Animation(
        FAnimation.animationStore.moveRight,
        { x: 5, originX: 3.25 },
        [11, 12]
      )
    ]);
    this.fa.createElAnimations(this.$refs.stage3bg, [
      new Animation(FAnimation.animationStore.fadeIn, {}, [-1, 0]),
      new Animation(FAnimation.animationStore.moveRight, { x: 5 }, [-1, 0]),
      new Animation(FAnimation.animationStore.fadeOut, {}, [12, 13])
    ]);
    this.fa.createElAnimations(this.$refs.desc1, [
      new Animation(FAnimation.animationStore.fadeIn, {}, [0, 1]),
      new Animation(FAnimation.animationStore.moveDown, { y: 2 }, [0, 1]),
      new Animation(
        FAnimation.animationStore.moveDown,
        { y: 2.5, originY: 2 },
        [2, 3]
      ),
      new Animation(
        FAnimation.animationStore.moveDown,
        { y: 2.5, originY: 4.5 },
        [4, 5]
      ),
      new Animation(
        FAnimation.animationStore.moveDown,
        { y: 2.5, originY: 7 },
        [6, 7]
      ),
      new Animation(FAnimation.animationStore.fadeOut, {}, [8, 9]),
      new Animation(FAnimation.animationStore.moveRight, { x: 5, y: 9.5 }, [
        8,
        9
      ])
    ]);
    this.fa.createElAnimations(this.$refs.content1, [
      new Animation(FAnimation.animationStore.fadeIn, {}, [1, 2]),
      new Animation(FAnimation.animationStore.moveRight, { x: 425 / 16 }, [
        1,
        2
      ])
    ]);
    this.fa.createElAnimations(this.$refs.desc2, [
      new Animation(FAnimation.animationStore.fadeIn, {}, [2, 3]),
      new Animation(FAnimation.animationStore.moveDown, { y: 2 }, [2, 3]),
      new Animation(
        FAnimation.animationStore.moveDown,
        { y: 2.5, originY: 2 },
        [4, 5]
      ),
      new Animation(
        FAnimation.animationStore.moveDown,
        { y: 2.5, originY: 4.5 },
        [6, 7]
      ),
      new Animation(FAnimation.animationStore.fadeOut, {}, [8, 9]),
      new Animation(FAnimation.animationStore.moveRight, { x: 5, y: 7 }, [8, 9])
    ]);
    this.fa.createElAnimations(this.$refs.content2, [
      new Animation(FAnimation.animationStore.fadeIn, {}, [3, 4]),
      new Animation(FAnimation.animationStore.moveRight, { x: 425 / 16 }, [
        3,
        4
      ])
    ]);
    this.fa.createElAnimations(this.$refs.desc3, [
      new Animation(FAnimation.animationStore.fadeIn, {}, [4, 5]),
      new Animation(FAnimation.animationStore.moveDown, { y: 2 }, [4, 5]),
      new Animation(
        FAnimation.animationStore.moveDown,
        { y: 2.5, originY: 2 },
        [6, 7]
      ),
      new Animation(FAnimation.animationStore.fadeOut, {}, [8, 9]),
      new Animation(FAnimation.animationStore.moveRight, { x: 5, y: 4.5 }, [
        8,
        9
      ])
    ]);
    this.fa.createElAnimations(this.$refs.content3, [
      new Animation(FAnimation.animationStore.fadeIn, {}, [5, 6]),
      new Animation(FAnimation.animationStore.moveRight, { x: 425 / 16 }, [
        5,
        6
      ])
    ]);
    this.fa.createElAnimations(this.$refs.desc4, [
      new Animation(FAnimation.animationStore.fadeIn, {}, [6, 7]),
      new Animation(FAnimation.animationStore.moveDown, { y: 2 }, [6, 7]),
      new Animation(FAnimation.animationStore.fadeOut, {}, [8, 9]),
      new Animation(FAnimation.animationStore.moveRight, { x: 5, y: 2 }, [8, 9])
    ]);
    this.fa.createElAnimations(this.$refs.content4, [
      new Animation(FAnimation.animationStore.fadeIn, {}, [7, 8]),
      new Animation(FAnimation.animationStore.moveRight, { x: 425 / 16 }, [
        7,
        8
      ])
    ]);
    this.fa.createElAnimations(this.$refs.text, [
      new Animation(FAnimation.animationStore.fadeIn, {}, [9, 10]),
      new Animation(FAnimation.animationStore.moveDown, { y: 2 }, [9, 10]),
      new Animation(FAnimation.animationStore.fadeOut, {}, [10, 11]),
      new Animation(FAnimation.animationStore.moveRight, { x: 5, y: 2 }, [
        10,
        11
      ])
    ]);
    window.addEventListener("scroll", this.execAnimationList, false);
  },
  beforeDestroy() {
    // window.removeEventListener("resize", this.onResize);
    window.removeEventListener("scroll", this.execAnimationList);
    this.fa.destroy();
  },
  methods: {
    onResize() {
      this.$refs.stage3.style.height = this.$root.windowHeight * 6.5 + "px";
      // this.$refs.stage3.style.height = window.innerHeight * 6.5 + "px";
    },
    dynamicFrameHeight() {
      window.addEventListener("resize", () => {
        this.frameHeight = window.innerHeight / 2;
      });
    },
    execAnimationList() {
      this.fa.setFixedOpportunity();
      this.fa.execAnimationList();
    }
  }
};
</script>

<style lang="scss" scoped>
.stage3 {
  height: 650vh;
  background-color: #000000;
  color: #fff;
  .stage3-inner {
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    .stage3-bg {
      width: calc(100vw + 5rem);
      height: 100vh;
      position: absolute;
      top: 0;
      left: 0;
      color: red;
      left: -5rem;
      background-image: url("../../../assets/images/bg2.png");
      background-repeat: no-repeat;
      background-size: cover;
      opacity: 0;
    }
    .intelligent {
      position: absolute;
      top: calc(50vh - 16px - 65px);
      font-size: 65px;
      line-height: 65px;
      left: 238px;
      opacity: 0;
    }
    .short-desc {
      position: absolute;
      top: calc(50vh - 2rem);
      left: 290px;
      display: flex;
      width: 800px;
      opacity: 0;
      line-height: 25px;
      font-size: 21px;
      opacity: 0;
      .title {
        flex-shrink: 0;
        color: rgba(176, 190, 242, 1);
      }
      .content {
        position: relative;
        flex: 1;
        font-weight: 200;
        overflow: hidden;
        .content-inner {
          position: absolute;
          left: -400px;
          opacity: 0;
        }
      }
    }
    .text {
      width: 650px;
      line-height: 32px;
      position: absolute;
      top: calc(50vh - 2rem);
      left: 290px;
      font-size: 20px;
      font-weight: 200;
      opacity: 0;
    }
  }
}
</style>
