<template>
  <div class="home" ref="home">
    <stage1 ref="stage1"></stage1>
    <stage2 ref="stage2"></stage2>
    <stage3 ref="stage3"></stage3>
    <stage4 ref="stage4"></stage4>
    <stage5 ref="stage5"></stage5>
    <viewport ref="viewport" :home="home"></viewport>
  </div>
</template>

<script>
import Stage1 from "@/components/mobile/stage1";
import Stage2 from "@/components/mobile/stage2";
import Stage3 from "@/components/mobile/stage3";
import Stage4 from "@/components/mobile/stage4";
import Stage5 from "@/components/mobile/stage5";
import Viewport from "@/components/mobile/viewport";
export default {
  name: "home-mobile",
  components: {
    Stage1,
    Stage2,
    Stage3,
    Stage4,
    Stage5,
    Viewport
  },
  data() {
    return {
      home: null,
      timer: 0,
      originWidth: 0
    };
  },
  mounted() {
    window.scrollTo(0, 0);
    this.originWidth = window.innerWidth;
    this.home = this.$refs.home;
    setTimeout(() => {
      this.clearAllAnimation();
    }, 40);
  },
  beforeDestroy() {
    window.scrollTo(0, 0);
    clearTimeout(this.timer);
  },
  methods: {
    clearAllAnimation() {
      this.clearAnimation(this.$refs.stage1.fa);
      this.clearAnimation(this.$refs.stage2.fa);
      this.clearAnimation(this.$refs.stage3.fa);
      this.clearAnimation(this.$refs.stage4.fa);
      this.clearAnimation(this.$refs.stage5.fa);
      this.clearAnimation(this.$refs.viewport.fa);
    },
    clearAnimation(fa) {
      if (fa) {
        fa.clearAnimation();
      }
    }
  }
};
</script>

<style lang="scss">
.home {
  width: 100vw;
  overflow: hidden;
}
</style>
